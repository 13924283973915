body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.errorTxt {
  color: red;
}

.subtitle {
  margin-top: 20px !important;
}

#portfolio_section {
  padding-top: 40px;
  padding-bottom: 70px;
}

.modal-card-body {
  margin: 20px;
}

@media only screen and (max-width: 350px) {

  .rc-tabs-nav span {
    font-size: 13px;
  }

  .app_image_area, .play_image_area {
    width: 120px !important;
  }
  
}

@media only screen and (max-width: 370px) {

  #testimonial_section {
    display: none;
  }

}

@media only screen and (max-width: 480px) {

  #work {
    padding-top: 0 !important;
  }

  #portfolio_section {
    padding-top: 0 !important;
  }

  .rc-tabs-tab {
    display: block !important;
  }

  #banner_section {
    padding-bottom: 50px;
  }

  #testimonial_section {
    margin-top: 10px;
  }

  .social-icons {
    margin-top: 20px;
  }

}

@media only screen and (max-width: 768px) {

  .mainRow {
    margin-top: 0 !important;
  }
  
}

.companyContact {
  display: flex;
  flex-direction: column;
  opacity: 0.7;
}

.social-icons {
  display: block;
}

@media only screen and (max-width: 700px) {

  .copyRight {
    display: flex;
    flex-direction: column;
  }

  .companyContact {

    margin-top: 30px;

  }

  #about-us {
    padding-top: 0;
  }
  
}

@media only screen and (min-width: 700px) {

  .companyContact {

    margin-left: auto;

  }
  
}

@media only screen and (min-width: 750px) and (max-width: 1200px) {

  .appDownload {

    margin-top: 0 !important;

  }
  
}

.referencePhoto {
  width: 50px;
  margin-right: 20px;
}

.glide__slide--active {
  z-index: 1;
}
